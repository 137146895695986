export function csvToJson(csv) {
    const lines = csv.split("\n").filter(line => line.trim() !== "");
    const headers = lines.shift().split(",");
    const result = [];

    lines.forEach(line => {
        const values = line.split(",");
        let obj = {};
        headers.forEach((header, index) => {
            obj[header.trim()] = values[index].trim();
        });
        result.push(obj);
    });

    return result;
}