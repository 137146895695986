import AutoPlayVideo from "../components/AutoPlayVideo";
import Slider from "../utils/Slider";
import {useEffect, useState} from "react";
import {csvToJson} from "../utils/utils";

const _ = require('lodash');

const GID = 2031001462;
const URL = `https://docs.google.com/spreadsheets/d/e/2PACX-1vSHhtyVECMigsTQGhL-SUaau-9cgWyn4wS--hpL_sqD49qTAmTHU2GYkFGcGj5cpcrTDYqbiFbaLrRm/pub?gid=${GID}&single=true&output=csv`;

export default function Photographie() {
  const [groups, setGroups] = useState([])

  useEffect(() => {
    fetch(URL)
        .then(async res => {
          res.text().then(text => {
            const obj = csvToJson(text);
            setGroups(Object.values(_.groupBy(obj, 'groupe')))
          })
        })
  })

  return (
      <section className="page">
        <div className="page__bg-video">{AutoPlayVideo("bg/bg-walashi.mp4")}</div>
        {groups && groups.map(g => {
          return <Slider items={g}/>
        })}
      </section>
  );
}

